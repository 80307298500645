.update-restaurant-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50rem;
}

.update-restaurant-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 50rem;
    height: 35rem;
    border-radius: 0.3rem;
}