.delete-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 50%;
    margin: 30px 0;
    border-radius: 10px;
    background: white;
    padding: 1rem;
}

.delete-container h2 {
    color: black;
    margin-bottom: 0;
    margin-top: 10px;
}

.delete-container p {
    color: black;
    margin-top: 0;
}

.yes-button1 {
    background-color: rgb(107, 185, 240);
    color: white;
    border-radius: 5px;
    margin: 3px;
    padding: 3px;
    cursor: pointer;
}

.no-button1 {
    color: black;
    background-color: white;
    border-radius: 5px;
    margin: 3px;
    padding: 3px;
    cursor: pointer;
}
