.update-review-image-container2 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 45rem;
	width: 60rem;
	border-radius: 10px;
	background: white;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	padding-top: 1.5rem;
	border: 2px solid black;
}

.update-img-btn-container2 form {
	width: 70rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.update-img-btn-container2 {
	display: flex;
	justify-content: space-between;
	width: 50rem;
	margin: 1rem 0 0 0;
}

#current-img-update-img2 {
	object-fit: cover;
	object-position: center;
	width: 50rem;
	height: 20rem;
	border-radius: 0.5rem;
	transition: 0.7s ease;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.upload-img-icon-container2 {
	border: 0.1px solid rgba(128, 128, 128, 0.5);
	height: 7rem;
	border-radius: 0.5rem;
	width: 50rem;
}

.upload-img-icon-container2:hover {
	cursor: pointer;
}

