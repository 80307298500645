body {
	margin: 0;
	padding: 0;
}
html {
	height: 100vh;
	margin: 0;
	padding: 0;
}

/* .create-restaurant-container {
    display: flex;
    flex-direction: column;
    width: 55rem;
    margin-top: 50px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    align-self: center;
}
.create-restaurant-form {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    width: 55rem;
}

.create-wrapper-master {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-heading {
    font-size: 24px;
    margin-bottom: 10px;
    align-self: center;
}

.form-group {
    margin: 10px;
}

.form-group.category {
    width: 9rem;
}
.form-group.price {
    width: 80px;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.input-field {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.input-field.error {
    border-color: #ff0000;
}

.error-message {
    color: #ff0000;
    font-size: 14px;
}

.create-restaurant-btn {
    background-color: rgb(107, 185, 240);
    color: white;
    font-size: 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 12rem;
    height: 2.5rem;
} */

.auto-complete-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 45rem;
	margin: 2rem 0 0 0;
}

#auto-complete-box {
	width: 38rem;
	border-radius: 0.3rem;
	border: 2px solid rgba(107, 185, 240);
	height: 2.5rem;
}

#res-address-create {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 38.5rem;
	border-radius: 0.3rem;
}

.form-heading-create {
	display: flex;
	justify-content: center;
	font-weight: bold;
	font-size: 1.5rem;
	margin: 0 0 2.5rem 0;
}

#price-create,
#category-create {
	width: 10rem;
	height: 2.9rem;
	margin: 0 0 0 0.5rem;
	border-radius: 0.3rem;
	border: 2px solid rgba(107, 185, 240);
	cursor: pointer;
}

#price-create:focus,
#category-create:focus {
	outline: none;
}

.create-small-boxers {
	margin: 0 0 0 0.6rem;
}

.create-wrapper-master {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 99vh;
	flex-direction: column;
}

.within-form-master-container {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	height: 25rem;
	width: 45rem;
}

.create-restaurant-container {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	width: 55rem;
	height: 40rem;
	border-radius: 0.3rem;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.res-name-price-cat-container {
	display: flex;
	justify-content: center;
	width: 45rem;
}

#restaurantName {
	width: 17rem;
	height: 2.5rem;
	border-radius: 0.3rem;
	border: 2px solid rgba(107, 185, 240);
}

.error {
	border-color: #ff6347; /* Light Coral */
}

.error-message {
	color: red;
	font-weight: 800;
	margin: 0.2rem 0 0 0.6rem;
	font-size: 0.85rem;
}

.error-message-name {
	color: red;
	font-weight: 800;
	margin: 0.2rem 0 0 0;
	font-size: 0.85rem;
}

.form-row-images-create {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 45rem;
	margin: 2rem 0 0 0;
}

.attch-photos-txt-create {
	display: flex;
	width: 38.5rem;
}

.create-res-btn-container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 2rem 0 0 0;
}

.create-restaurant-btn {
	background-color: rgb(107, 185, 240);
	color: white;
	border: none;
	border-radius: 0.3rem; /* 4px in rem */
	cursor: pointer;
	font-size: 1rem; /* 16px in rem */
	width: 38.5rem;
	height: 3rem;
	transition: 0.5s ease;
}

.create-restaurant-btn:hover {
	box-shadow: 0.5px 0.5px #373737, 1px 1px #373737, 2px 2px #373737,
		3px 3px #373737;
	-webkit-transform: translateX(-3px);
	transform: translateX(-3px);
	transition: 0.5s ease;
}

.create-restaurant-btn:disabled {
	background-color: #ccc;
	cursor: not-allowed;
}

.upload-img-icon-container-create {
	border: 0.1px solid rgba(128, 128, 128, 0.5);
	height: 6rem;
	border-radius: 0.5rem;
	width: 38.5rem;
}

.create-image-input-create {
	width: 2rem;
	height: 0.01rem;
	border: solid 0.1px rgb(14, 14, 14, 0.3);
	border-radius: 4px;
	color: rgb(107, 185, 240);
	transition: 0.2s ease-in-out;
	visibility: hidden;
}

.create-image-input-create::placeholder {
	color: #888888;
}

#upload-image-icon-create:hover {
	color: rgb(107, 185, 240);
}

.upload-img-icon-container-create:hover {
	cursor: pointer;
}

#upload-image-icon-create {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	height: 7rem;
	font-size: 2rem;
	transition: 0.5s ease;
}

#image-name-create {
	color: black;
	font-size: 1rem;
	margin: 0.5rem 0 0 0;
}

.create-res-image-error {
	width: 38.5rem;
	color: red;
	font-weight: 800;
	margin: 0.2rem 0 0 0;
	font-size: 0.85rem;
}

.error-message-invalidAddy {
	width: 38.5rem;
	color: red;
	font-weight: 800;
	margin: 0.2rem 0 0 0;
	font-size: 0.85rem;
}
