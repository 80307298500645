.master-container {
	display: flex;
	justify-content: space-between;
}

.all-restaurant-tiles {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 0 0 1rem;
}

.res-detail-body {
	display: flex;
	flex-direction: column;
	margin: 5rem 0 2rem 0;
	overflow-y: scroll;
	overflow-x: hidden;
	height: 50.7rem;
	width: 50vw;
}

.res-detail-body::-webkit-scrollbar {
	display: none;
}

.res-detail-body::-webkit-scrollbar-thumb {
	background-color: rgb(107, 185, 240);
	background-size: 100%;
	border-radius: 0.3rem;
	padding-left: 50px;
}

.h1-lameo {
	margin: 1rem 0 1rem 1rem;
	color: #0e0e0e;
	font-size: 2rem;
	font-weight: 600;
	border-bottom: 1px solid rgba(0, 0, 0, 0.13);
	height: 4rem;
}

.actl-res-img {
	width: 15rem;
	height: 13rem;
	object-fit: cover;
	border-radius: 0.3rem;
}

.indv-res-info-container {
	display: flex;
	border-bottom: 1px solid rgba(0, 0, 0, 0.13);
	border-radius: 0.3rem;
	width: 50rem;
	margin: 0 0 1rem 0;
	padding: 0.75rem;
	transition: 0.5s ease;
}
.indv-res-info-container:hover {
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.res-info-container {
	margin: 0 0 0 1.5rem;
}

.indv-res-navlink {
	width: 50rem;
}

.indv-res-navlink:visited,
.indv-res-navlink:link,
.indv-res-navlink:hover,
.indv-res-navlink:active {
	text-decoration: none;
	color: black;
}

#res-name-text {
	font-size: 1.3rem;
	font-weight: 500;
}

#review-star {
	text-align: center;
	border-radius: 0.3rem;
	font-size: 0.7rem;
	background-color: rgb(107, 185, 240);
	margin: 0 0.2rem 0 0;
	padding: 0.3rem;
	color: white;
}

#review-star-half {
	font-size: 0.7rem;
	text-align: center;
	border-radius: 0.3rem;
	background: linear-gradient(
		to right,
		rgb(107, 185, 240) 0%,
		rgb(107, 185, 240) 50%,
		rgba(0, 0, 0, 0.199) 50%,
		rgba(0, 0, 0, 0.199) 100%
	);
	margin: 0 0.2rem 0 0;
	padding: 0.3rem;
	color: white;
}

.rating-div {
	display: flex;
	align-items: center;
}

.rating-text {
	font-size: 0.9rem;
	font-weight: bold;
	color: rgba(0, 0, 0, 0.623);
	margin: 0 0 0 0.3rem;
}

#num-reviews-text {
	font-weight: normal;
}

.category-price-container {
	display: flex;
	align-items: center;
	margin: 0.3rem 0 0 0;
}

#cat-text {
	display: flex;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.089);
	font-size: 0.7rem;
	padding: 0.1rem;
	font-weight: bold;
	color: rgba(0, 0, 0, 0.678);
	border-radius: 0.3rem;
	height: 1rem;
	margin: 0 0.3rem 0 0;
}

#center-dot-text {
	display: flex;
	align-items: center;
	margin: 0 0.3rem 0 0;
}

#price-text {
	display: flex;
	align-items: center;
	font-size: 0.8rem;
	margin: 0 0.3rem 0 0;
}

#neighborhood-text {
	display: flex;
	align-items: center;
	font-size: 0.8rem;
	margin: 0 0.3rem 0 0;
	font-weight: 500;
	color: rgba(0, 0, 0, 0.678);
}

.latest-review-text {
	display: flex;
	margin: 0.5rem 0 0 0;
	color: rgba(0, 0, 0, 0.678);
	font-style: italic;
}

#review-text-search {
	display: flex;
	align-items: center;
	font-size: 0.9rem;
}

#review-bubble-icon {
	font-size: 0.9rem;
	margin: 0 0.4rem 0 0;
	font-weight: bolder;
}

#bubble-icon-container {
	display: flex;
	align-items: center;
	height: 1rem;
}

.goggle-maps {
	width: 50vw;
	display: flex;
	font-size: 10rem;
	/* height: 52.6rem; */
	margin: 0 0 0.2rem 0;
	border-radius: 0.3rem;
	align-items: flex-end;
	justify-content: center;
	height: 56.8rem;
}

#no-res-text {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 5rem;
	font-weight: bolder;
	font-style: italic;
}

.marker-tester {
	display: flex;
	flex-direction: column;
	border-radius: 0.3rem;
}

.marker-rating-container {
	display: flex;
}

#marker-rest-img {
	object-fit: cover;
	width: 15rem;
	height: 10rem;
	border-radius: 0.3rem;
}

#marker-rest-name {
	margin: 0.2rem 0 0 0;
	font-weight: bold;
	transition: 0.3s ease;
}

#marker-rest-name:hover {
	color: rgb(107, 185, 240);
	text-decoration: underline;
	cursor: pointer;
}

#marker-star {
	display: flex;
	align-items: center;
	color: rgb(107, 185, 240);
	margin: 0 0.1rem 0 0;
	font-size: 0.7rem;
}

#marker-rest-rating {
	display: flex;
	align-items: center;
	font-size: 0.85rem;
	font-weight: 500;
}

.marker-rating-container {
	display: flex;
	align-items: center;
	font-weight: 500;
}

#marker-center-dot {
	font-size: 1.5rem;
	font-weight: bolder;
	margin: 0 0.2rem 0 0.2rem;
	font-weight: 500;
}

.marker-info-window {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

#marker-new-text {
	font-size: 0.85rem;
}

.marker-star-rating-both {
	display: flex;
	align-items: center;
}

@media screen and (max-width: 1700px) {
	.goggle-maps {
		width: 40vw;
	}

	.res-detail-body {
		width: 55vw;
	}
}

@media screen and (max-width: 1160px) {

	.goggle-maps {
		display: none;
	}

	.res-detail-body {
		width: 100vw;
		height: 91vh;
	}
}
