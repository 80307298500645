.review-content-main {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.form-row-stars-rating-container {
	border: solid 0.1px rgb(14, 14, 14, 0.3);
	border-radius: 0.5rem;
	padding-left: 25px;
	width: 40rem;
	height: 25rem;
	transition: 0.2s ease-in-out;
}

.form-row-stars-rating-container:hover {
	border: 0.1px solid rgb(107, 185, 240);
}

.create-review-input {
	resize: none;
	width: 578px;
	height: 208px;
	margin-left: 8px;
	margin-top: 15px;
	border: none;
	color: rgb(107, 185, 240);
	font-weight: 1000;
}

.create-review-input:focus,
input:focus {
	outline: none;
}

.create-review-title {
	margin: 8rem 0 2rem 0;
	text-align: center;
}

.rating {
	display: flex;
	align-items: center;
	margin: 10px;
}

.stars {
	display: flex;
	flex-direction: row-reverse;
	margin: 0.5rem 0 0 0;
}

.stars-text {
	display: flex;
	align-items: center;
	font-size: 0.9rem;
	margin: 0 0 0 0.4rem;
	color: rgb(107, 185, 240);
	font-weight: 300;
}

.star {
	margin: 0 0.2rem 0 0;
	color: white;
	font-size: 1rem;
	transition: 0.5s ease;
	background-color: rgb(220, 220, 223);
	padding: 0.3rem 0.5rem 0.3rem 0.5rem;
	border-radius: 0.5rem;
	cursor: pointer;
}

.star.filled {
	background-color: rgb(107, 185, 240);
}

.star:hover {
	background-color: rgb(107, 185, 240);
	transition: 0.5s ease;
}

.star:hover~.star {
	background-color: rgb(107, 185, 240);
}

.things-to-consider-container {
	display: block;
}

.things-to-consider-text {
	display: block;
	font-size: 15px;
	padding-left: 13px;
	padding-top: 5px;
	color: 6e7072;
	padding-bottom: 5px;
}

.things-to-consider-categories-food,
.things-to-consider-categories-service,
.things-to-consider-categories-ambience {
	font-size: 11px;
	font-weight: bold;
	margin-left: 12px;
	background-color: rgb(220, 220, 223);
	padding: 0 0.4rem 0 0.4rem;
	border-radius: 3px;
	color: rgb(110, 112, 114);
}

.images-master-parent {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 625px;
}

.review-url-container {
	margin: 2rem 0 0 0;
}

.create-image-input {
	width: 41.5rem;
	height: 0.01rem;
	border: solid 0.1px rgb(14, 14, 14, 0.3);
	border-radius: 4px;
	color: rgb(107, 185, 240);
	transition: 0.2s ease-in-out;
}

.upload-img-icon-container {
	border: 0.1px solid rgba(128, 128, 128, 0.5);
	height: 7rem;
	border-radius: 0.5rem;
	/* box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 5px; */
	width: 41.5rem;
}

.create-image-input::placeholder {
	color: #888888;
}

#upload-image-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 7rem;
	font-size: 2rem;
	transition: 0.5s ease;
}

#upload-image-icon:hover {
	color: rgb(107, 185, 240);
}

.upload-img-icon-container:hover {
	cursor: pointer;
}

.attch-photos-txt {
	font-size: 1.3rem;
	margin: 0 0 0.5rem 0.2rem;
	font-weight: bold;
}

.create-review-submit-button {
	width: 200px;
	height: 44px;
	border: none;
	border-radius: 4px;
	margin-right: 450px;
	margin-top: 20px;
	color: #ffffff;
	background-color: rgb(107, 185, 240);
	transition: 0.5s ease;
	cursor: pointer;
	font-size: 1rem;
	font-weight: 550;
}

.create-review-submit-button:hover {
	box-shadow: 0.5px 0.5px #373737, 1px 1px #373737, 2px 2px #373737,
		3px 3px #373737;
	-webkit-transform: translateX(-3px);
	transform: translateX(-3px);
	transition: 0.5s ease;
}

.create-review-error-div {
	width: 41.5rem;
	color: rgb(107, 185, 240);
	font-weight: 800;
}

.all-errors-reviews {
	display: flex;
	flex-direction: column;
}

.create-review-error-text {
	font-size: 15px;
}

.create-review-image-error {
	color: rgb(107, 185, 240);
	font-weight: 800;
	font-size: 15px;
}
