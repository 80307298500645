#create-images-form-modal {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 50%;
	margin: 30px 0;
	border-radius: 10px;
	background: white;
	/* border: 1px solid black */
	padding-left: 15px;
	padding-right: 15px;
	padding-bottom: 10px;
}

#create-images-form-modal h2 {
	color: black;
}

.add-photos-submit-button {
	align-self: center;
	width: 200px;
	height: 44px;
	border: none;
	border-radius: 0.3rem;
	color: #ffffff;
	background-color: rgb(107, 185, 240);
	transition: 0.5s ease;
	cursor: pointer;
}

.add-photos-submit-button:hover {
	box-shadow: 0.5px 0.5px #373737, 1px 1px #373737, 2px 2px #373737,
		3px 3px #373737;
	-webkit-transform: translateX(-3px);
	transform: translateX(-3px);
	background-color: rgb(142, 205, 250);
}

#image-name2 {
	color: black;
	font-size: 1rem;
	margin: 0 0 0 0.2rem;
}

.attch-photos-txt2 {
	font-size: 1.3rem;
	margin: 0.5rem 0 0 0.2rem;
	font-weight: bold;
}

