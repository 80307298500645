.profile-dropdown {
	list-style: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	position: absolute;
	left: 87%;
	background: white;
	height: 16rem;
	width: 15rem;
	border-radius: 0.3rem;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	margin: 0.3rem 0 0 0;
	padding: 0;
}

.hidden {
	display: none;
}

.profile-button {
	background-color: transparent;
	font-size: 20px;
	border: 2px solid rgb(107, 185, 240);
	border-radius: 25px;
	color: rgb(107, 185, 240);
	align-self: center;
	padding: 5px 20px 5px 20px;
	margin: 0 15px 0 0;
	transition: 0.5s ease;
}

.profile-button:hover {
	background-color: rgb(107, 185, 240);
	color: white;
}

#profile-icon {
	font-size: 23px;
	align-self: center;
}

#welcome-text {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.2rem;
}

.profile-dropdown-login-out-btns button {
	display: flex;
	align-items: center;
	border: none;
	border-radius: 0.3rem;
	width: 12rem;
	height: 2.5rem;
	color: white;
	background-color: rgb(107, 185, 240);
	transition: 0.5s ease;
	cursor: pointer;
	/* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

.profile-dropdown-login-out-btns button:hover {
	background-color: rgb(136, 198, 243);
}

.homepage-logout-button {
	border: none;
	border-radius: 0.3rem;
	width: 12rem;
	height: 2rem;
	color: white;
	background-color: rgb(107, 185, 240);
	transition: 0.5s ease;
	cursor: pointer;
	/* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
	margin: 1rem 0 0 0;
}

.homepage-logout-button:hover {
	background-color: rgb(136, 198, 243);
}

.profile-button:hover {
	cursor: pointer;
}

.profile-image {
	height: 40px;
	width: 40px;
	border-radius: 20px;
	color: rgb(107, 185, 240);
}

.nav-bar {
	top: 0;
	position: fixed;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	text-decoration: none;
	align-items: center;
	list-style: none;
	padding: 0;
	margin: 0;
	background-color: white;
	z-index: 999;
	height: 5rem;
}

.home-button {
	padding-left: 20px;
	height: 50px;
	width: 90px;
	text-decoration: none;
}

.search-bar-div-container {
	display: flex;
	width: 40rem;
	border-top-left-radius: 0.3rem;
	border-bottom-left-radius: 0.3rem;
	height: 2.5rem;
	padding: 0.2rem;
}

.outer-search-container {
	display: flex;
	align-items: center;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	height: 2.9rem;
	border-radius: 0.3rem;
}

.search-results-container {
	display: flex;
	align-items: center;
}

.search-results-info {
	display: flex;
	flex-direction: column;
}

#search-results-address {
	font-size: 0.7rem;
}

#search-results-name {
	font-size: 0.9rem;
}

#search-img-results {
	object-fit: cover;
	height: 2rem;
	width: 2rem;
	border-radius: 0.3rem;
	margin: 0 1rem 0 0;
}

/* .search-bar {
	cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: transparent;
  padding: 10px 20px;
  border-radius: 30px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
  width: 35rem;
  height: 1.5rem;
  align-self: center;
} */

#combo-box-demo {
	width: 20rem;
	height: 1.9rem;
	border-radius: 0.3rem;
}

.MuiInput-underline {
	margin: 0 0 0 0.2rem;
	border-radius: 0.3rem;
}

.MuiAutocomplete-endAdornment,
.css-1q60rmi-MuiAutocomplete-endAdornment {
	margin: 0 0.2rem 0 0;
}

.search-bar-form {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 2rem;
	width: 100%;
	justify-content: space-evenly;
}

.search-bar-form input {
	width: 400px;
}

.search-input {
	width: 0;
	outline: none;
	border: none;
	transition: 0.3s;
	background: transparent;
}

.login-signup-buttons {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 10rem;
}

.search-btn-container {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(107, 185, 240);
	height: 2.5rem;
	width: 3rem;
	border-top-right-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
	padding: 0.2rem;
}

.search-submit-button {
	text-decoration: none;
	border: none;
	background-color: transparent;
	border-radius: 0.3rem;
	font-size: 1.4rem;
}

.search-submit-button:hover {
	cursor: pointer;
}

/* .search-bar-form select {
  visibility: hidden;
  width: 0;
} */

.search-bar-form select {
	visibility: visible;
	width: 3.8rem;
}

.create-restaurant-button {
	color: grey;
	text-decoration: none;
	justify-self: flex-end;
	margin-right: 8px;
	margin-top: 8px;
}

.create-restaurant-button:hover {
	transition: 0.5s;
	color: black;
}

.create-restaurant {
	display: flex;
}

.genre-input-1 {
	border: none;
	border-radius: 3px;
	font-size: smaller;
	color: black;
	background-color: transparent;
	width: 100%;
	cursor: pointer;
}

option:hover {
	background-color: rgb(107, 185, 240);
}

#option-wrapper {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	height: 2.421rem;
}

#option-wrapper select {
	width: 6rem;
	height: 1.5rem;
}

.price-input-1:focus,
.genre-input-1:focus {
	outline: none;
}

.price-input-1 {
	border: none;
	border-radius: 3px;
	font-size: smaller;
	color: black;
	background-color: transparent;
	cursor: pointer;
}

.price-input-1 option {
	width: 4rem;
	border: none;
}

#corner-nav-container {
	display: flex;
}

.welcome-msg-container {
	display: flex;
	align-items: center;
	margin: 0 0 0.2rem 0;
	border-bottom: 1px solid rgb(107, 185, 240);
}

#profile-icon-dropdown {
	color: rgb(107, 185, 240);
	margin: 0 0.2rem 0 0;
}

.profile-icon-container-dropdown {
	display: flex;
	align-items: center;
	margin: 0 0.2rem 0.4rem 0;
}

.hello-user-msg-container {
	display: flex;
	align-items: center;
	margin: 0 0 0.4rem 0;
	font-size: 1rem;
}

.date-time-container {
	display: flex;
	flex-direction: column;
	font-size: 0.8rem;
	border-bottom: 1px solid rgb(107, 185, 240);
}

.date-nav {

	margin: 0 0.2rem 0 0;
}

.date-time-only {
	display: flex;
	margin: 0 0 0.2rem 0;
}

.profile-dropdown-options-container {
	margin: 0.5rem 0 0.5rem 0;
	border-bottom: 1px solid rgb(107, 185, 240);
}

#food-symbol-profile-dropdown {
	display: flex;
	align-items: center;
	font-size: 1rem;
	margin: 0 0.2rem 0 0;
}

#settings-symbol-profile-dropdown {
	display: flex;
	align-items: center;
	font-size: 1.4rem;
	margin: 0 0.2rem 0 0;
}

#create-restaurant-button-dropdown {
	display: flex;
	align-items: center;
	font-size: 0.9rem;
	text-decoration: none;
	color: black;
	margin: 0.5rem 0 0.5rem 0;
	transition: 0.3s ease;
}

#create-res-text-actl {
	display: flex;
	align-items: center;
	text-decoration: none;
	font-size: 0.9rem;
	margin: 0 0 0 0.2rem;
}

#create-restaurant-button-dropdown:hover {
	color: rgb(107, 185, 240);
}

#create-restaurant-button-dropdown:visited {
	text-decoration: none;
}

@media screen and (max-width: 850px) {
	.search-bar {
		width: 15rem;
		cursor: pointer;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		background: transparent;
		height: 1.5rem;
		align-self: center;
	}

	.genre-input-1 {
		display: none;
	}

	.price-input-1 {
		display: none;
	}

	#combo-box-demo {
		width: 9rem;
	}

	.search-bar-div-container {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
}
