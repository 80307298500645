.detail-body {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 5.1rem 0 0 0;
}

#res-img {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	height: 40rem;
	align-items: center;
	overflow: hidden;
}

.img-tile {
	width: 100vw;
	height: 40rem;
	object-fit: cover;
}

#res-img::before {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 31.42%, #000);
	pointer-events: none;
}

.info-thing {
	/* z-index: 1; */
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	margin: auto;
	background-color: transparent;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	width: 75%;
	height: 35rem;
}

#res-details {
	margin-left: 15%;
	margin-right: 15%;
}

#restaurant-name {
	color: white;
	font-size: 4rem;
	font-weight: bolder;
}

#restaurant-rtng {
	color: white;
	font-size: 2rem;
	font-weight: bold;
}

#restaurant-cat {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

#restaurant-price {
	color: white;
	font-size: 2rem;
	font-weight: bold;
}

#manage-buttons {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	/* background-color: blue; */
}

#option-buttons button {
	font-size: 90%;
	font-weight: 600;
	height: 3rem;
	width: 25%;
	padding: 0.2rem;
	color: white;
	background-color: rgb(107, 185, 240);
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	border-radius: 3px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: none;
}

#option-buttons button:hover {
	background-color: rgb(34, 163, 255);
	transition: ease-in-out 0.5s;
}

#option-buttons {
	padding-top: 1rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid rgb(182, 177, 177);
	display: grid;
	grid-template-columns: 1fr 1fr;
}

#location-tag {
	font-weight: bold;
	height: 100%;
}

#res-location {
	display: grid;
	grid-template-columns: 1fr 6fr;
	padding: 5px;
	/* background-color: red; */
}

#rec-review-text {
	font-weight: bold;
	font-size: 20px;
	padding-top: 1rem;
}

#rev-prof-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

#reviewer-username {
	font-weight: bold;
	margin-left: 0.5rem;
}

#prof-stars {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

#manage-rev-buttons {
	padding-top: 1rem;
	display: flex;
	justify-content: center;
}

#manage-rev-buttons button {
	font-size: 0.8rem;
	height: 3rem;
	width: fit-content;
	padding: 0.5rem;
	color: white;
	background-color: rgb(107, 185, 240);
	margin: 0.5rem;
	border-radius: 3px;
	cursor: pointer;
	border: none;
	align-items: center;
}

#manage-rev-buttons button:hover {
	background-color: rgb(34, 163, 255);
	transition: ease-in-out 0.5s;
}

#details-review-text {
	padding-bottom: 1rem;
}

#rev-imgs-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: 0 0 1rem 0;
}

#rev-imgs-container img {
	object-fit: cover;
	height: 12rem;
	width: 15rem;
	border-radius: 5px;
}

#profile-icon-2 {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 3.3rem;
	color: rgb(107, 185, 240);
	border-radius: 1.5rem;
}

#review-star-detail {
	border-radius: 0.3rem;
	font-size: 1rem;
	background-color: rgb(107, 185, 240);
	margin: 0 0.2rem 0 0;
	padding: 0.5rem;
	color: white;
}

#review-star-half-detail {
	font-size: 1rem;
	border-radius: 0.3rem;
	background: linear-gradient(
		to right,
		rgb(107, 185, 240) 0%,
		rgb(107, 185, 240) 50%,
		rgba(255, 255, 255, 0.466) 50%,
		rgba(255, 255, 255, 0.425) 100%
	);
	margin: 0 0.2rem 0 0;
	padding: 0.5rem;
	color: white;
}

.rating-text-detail {
	font-size: 1.5rem;
	font-weight: bold;
	color: white;
	margin: 0 0 0 0.3rem;
}

#num-reviews-text-detail {
	color: white;
}

/* .res-details-map-container {
	width: 100rem;
	border: 1px solid red;
} */

@media screen and (max-width: 1025px) {
	.res-details-map-container div {
		width: 10vw;
	}
}
