.profile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

}

.reviewed-res {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    height: 100%;
    margin-top: none;
}


.detail-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
