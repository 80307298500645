.login-container-modal {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 50%;
    margin: 30px 0;
    border-radius: 10px;
    background: white;
    width: 35rem;
    height: 38rem;
    /* border: 1px solid black */
}

.login-container-modal h2 {
    color: black;
    margin-bottom: 0;
    margin-top: 10px;
}

.login-container-modal p {
    color: black;
    margin-top: 0;
}

.username-field input{
    width: 25rem;
    border-radius: 10px;
    border: none;
    outline: none;
    border: 3px solid black;
    font-size: 16px;
    color: black;
    padding: 15px 40px 15px;
}
.username-field input::placeholder{
    color: black;
}
.password-field input::placeholder{
    color: black;
}

.password-field input{
    margin-top: 15px;
    width: 25rem;
    border-radius: 10px;
    border: none;
    outline: none;
    border: 3px solid black;
    font-size: 16px;
    color: black;
    padding: 15px 40px 15px;
}

.no-account-register  {
    color: black;
    margin-top: 25px;
}

#login-container-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



.password-field i {
    position: absolute;
    right: 40px;
    top: 293px;
    font-size: 25px;
}

.username-field i {
    position: absolute;
    right: 40px;
    top: 227px;
    font-size: 25px;
}

.register-button {
    color: rgb(107, 185, 240);
    cursor: pointer;
}

/* .login-for-modal {
    width: 55%;
    margin-left: 120px;
    margin-top: 15px;
    border-radius: 40px;
    height: 35px;
}

.login-for-modal:hover {
    box-shadow: 0 10px 25px rgba(0,0,0,0.3);
    cursor: pointer;
} */

.login-for-modal:hover {
    color: black;
    position: relative;
    height: 50px;
    width: 250px;
    cursor: pointer;
    margin-top: 15px;
    border: 3px solid transparent;
    border-radius: 5px;
    background-color: rgb(107, 185, 240);
}

.login-for-modal {
    color: rgb(107, 185, 240);
    transition: ease 0.5s;
    position: relative;
    height: 50px;
    width: 250px;
    cursor: pointer;
    margin-top: 15px;
    border: 3px solid rgb(107, 185, 240);
    border-radius: 5px;
}

  /* .login-for-modal::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255,255,255,0.3);
    transition: all 0.3s;
    border-radius: 10px;
}
  .login-for-modal:hover::before {
    opacity: 0 ;
    transform: scale(0.5,0.5);
}
  .login-for-modal::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border: 1px solid rgba(255,255,255,0.3);
    transform: scale(1.2,1.2);
    border-radius: 10px;
}
  .login-for-modal:hover::after {
    opacity: 1;
    transform: scale(1,1);
} */
.demo-button {
    margin-top: 5px;
    width: 50%;
    padding: 5px 10px;
    background-color: rgb(107, 185, 240);
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    border-radius: 8px;
}

#login-errors {
    list-style: none;
    color: red;
}
