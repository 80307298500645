#images-modal-container {
    display: flex;
    flex-direction: column;
    width: 90vw;
    height: 75vh;
    border-radius: 10px;
    background: white;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1.5rem;
    border: 2px solid black
}

#modal-res-name {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
}

#modal-img-tile {
    height: 11rem;
    width: 13rem;
    border-radius: 3px;
    object-fit: cover;
    margin-right: 0.5rem;
    overflow: scroll;
}

#imgs-url-container {
    display: flex;
    flex-direction: row;
}

.img-header-cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: black;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
}

.img-header-cont button {
    background: none;
    color: rgb(107, 185, 240);
    border: none;
    height: 2rem;
    font-size: 2rem;
    font-weight: bold;
    cursor: pointer;
}
