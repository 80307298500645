.signup-container-modal {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 32rem;
    height: 40rem;
    margin: 30px;
    border-radius: 20px;
    background: white;
    border: 1px solid black
}
.signup-container-modal h2 {
    color: black
}

.signup-for-modal:hover {
    color: black;
    position: relative;
    height: 50px;
    text-align: center;
    width: 250px;
    cursor: pointer;
    margin-top: 15px;
    border: 3px solid transparent;
    background-color: rgb(107, 185, 240);
    border-radius: 10px;
}

.signup-for-modal {
    color: rgb(107, 185, 240);
    position: relative;
    height: 50px;
    text-align: center;
    width: 250px;
    cursor: pointer;
    margin-top: 15px;
    border: 3px solid rgb(107, 185, 240);
    border-radius: 10px;
    transition: .5s ease;
}
/* .login-for-modal::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255,255,255,0.3);
    transition: all 0.3s;
    border-radius: 35px;
}
.login-for-modal:hover::before {
    opacity: 0 ;
    transform: scale(0.5,0.5);
}
.login-for-modal::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border: 1px solid rgba(255,255,255,0.3);
    transform: scale(1.2,1.2);
    border-radius: 35px;
}
.login-for-modal:hover::after {
    opacity: 1;
    transform: scale(1,1);
} */
.email-input-field input::placeholder {
    color: black;
}
.username-input-field input::placeholder {
    color: black;
}
.password-input-field input::placeholder {
    color: black;
}
.confirm-password-input-field input::placeholder {
    color: black;
}

.email-input-field input{
    margin-top: 10px;
    width: 24rem;
    border-radius: 10px;
    border: none;
    outline: none;
    border: 3px solid black;
    font-size: 16px;
    color: black;
    padding: 15px 40px 15px;
}

.username-input-field input{
    margin-top: 10px;
    width: 24rem;
    border-radius: 10px;
    border: none;
    outline: none;
    border: 3px solid black;
    font-size: 16px;
    color: black;
    padding: 15px 40px 15px;
}

.password-input-field input {
    margin-top: 10px;
    width: 24rem;
    border-radius: 10px;
    border: none;
    outline: none;
    border: 3px solid black;
    font-size: 16px;
    color: black;
    padding: 15px 40px 15px;
}

.confirm-password-input-field input{
    margin-top: 10px;
    width: 24rem;
    border-radius: 10px;
    border: none;
    outline: none;
    border: 3px solid black;
    font-size: 16px;
    color: black;
    padding: 15px 40px 15px;
}

.input-fields {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.existing-account-button p{
    color: black;
}

#signup-errors {
    list-style: none;
    color: red;
}

#signup-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

#signin-redirect {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
