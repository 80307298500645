* {
    font-family: 'Poppins', sans-serif;
}

html {
    padding: 0;
    margin: 0;
}

body {
    padding: 0;
    margin: 0;
}
