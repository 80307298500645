#backround-image {
	margin: 5rem 0 0 0;
	height: 40rem;
	background-attachment: local;
	background-size: cover;
	animation: change 15s infinite ease-in-out;
	background-position: bottom;
}
/* #backround-image2 {
    height: 500px;
    background-image: url(pexels-rachel-claire-5490965.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
  } */

@keyframes change {
	0% {
		background-image: url(https://images.pexels.com/photos/5490965/pexels-photo-5490965.jpeg);
	}
	20% {
		background-image: url(https://images.pexels.com/photos/2290753/pexels-photo-2290753.jpeg);
	}
	40% {
		background-image: url(https://images.pexels.com/photos/331107/pexels-photo-331107.jpeg);
	}
	60% {
		background-image: url(https://images.pexels.com/photos/4946625/pexels-photo-4946625.jpeg);
	}
	80% {
		background-image: url(https://images.pexels.com/photos/2290070/pexels-photo-2290070.jpeg);
	}
	100% {
		background-image: url(https://images.pexels.com/photos/2434649/pexels-photo-2434649.jpeg);
	}
}

/* review start */

#reviews-wrapper {
	max-width: 1200px;
	margin: 0 auto;
	padding: 1.5rem;
	display: flex;
	justify-content: center;
	flex-direction: column;
}
#recent-activity-text {
	display: flex;
	justify-content: center;
	font-size: 1.8rem;
}

#homepage-review {
	list-style: none;
	text-decoration: none;
	color: black;
}

.review-wrapper {
	padding: 15px;
	margin: 15px;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
	gap: 20px;
}

.single-review {
	display: flex;
	flex-direction: column;
	border: 1px solid rgba(116, 116, 116, 0.3);
	border-radius: 0.3rem;
	height: 25rem;
	justify-content: center;
	align-items: center;
	transition: 0.5s ease;
}
.single-review:hover {
	box-shadow: rgba(107, 185, 240, 0.35) 0px 5px 15px;
	border: 1px solid rgb(107, 185, 240);
}

.homepage-image {
	object-fit: cover;
	height: 10rem;
	width: 22.87rem;
}

#reviewer-review {
	display: flex;
	overflow-y: hidden;
	line-height: 1.1rem;
	justify-content: flex-start;
	width: 20rem;
	height: 5rem;
	margin: 0.5rem 0 0 0;
	font-size: 0.8rem;
}

.more-text {
	font-weight: bold;
}

#reviewer-stars {
	display: flex;
	width: 20rem;
	margin: 0.5rem 0 0 0;
}

#reviewer-username-home {
	font-weight: 600;
	font-size: .9rem;
}

#review-star-homepage {
	text-align: center;
	border-radius: 0.3rem;
	font-size: 0.7rem;
	background-color: rgb(107, 185, 240);
	margin: 0 0.2rem 0 0;
	padding: 0.3rem;
	color: white;
}

#profile-header {
	display: flex;
	justify-content: start;
	flex-direction: row;
	align-items: center;
	margin: 1rem 0 1rem 0;
	width: 21rem;
}

#prof-picture {
	font-size: 2.5rem;
	color: rgb(107, 185, 240);
}

#container-username-text {
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
	margin: 0 0 0 0.2rem;
}

#filler-text-review {
	font-size: 0.8rem;
}

#rst-name-in-review {
	font-size: 0.9rem;
	font-weight: bold;
	width: 20rem;
	margin: 1rem 0 0 0;
}
/* review end */

/* restaurant start */

.restaurant-container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 15px;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.restaurant-container h2 {
	align-self: center;
}

#header-text {
	font-size: 1.8rem;
}

.restaurant-wrapper {
	padding: 15px;
	margin: 15px;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
	gap: 20px;
}

.rst-img-home {
	width: 13rem;
	height: 13rem;
	object-fit: cover;
}

.single-restaurant {
	display: flex;
	flex-direction: row;
	justify-content: baseline;
}

#restaurant-link {
	list-style: none;
	text-decoration: none;
	color: black;
}
.single-restaurant h2 {
	padding: 10px;
	align-self: baseline;
	font-size: medium;
}
.single-restaurant p {
	padding: 10px;
	align-self: baseline;
	font-size: small;
	font-weight: lighter;
}
.single-restaurant h2:hover {
	text-decoration: underline;
}
.rec-res-container {
	border: 1px solid rgba(0, 0, 0, 0.1);
	height: 13rem;
	width: 35rem;
	border-radius: 0.3rem;
}
.rec-info-container {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	margin: 1rem 0 0 1rem;
	height: 6rem;
}
#rec-res-name {
	font-weight: bold;
	font-size: 1.2rem;
}
#rec-res-name:hover {
	text-decoration: 0.15rem solid underline;
}
.stars-rec {
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	margin: 0.5rem 0 0 0;
}
.star-rec {
	margin: 0 0.15rem 0 0;
	color: white;
	font-size: 0.9rem;
	transition: 0.5s ease;
	background-color: rgb(196, 196, 196);
	padding: 0.3rem 0.4rem 0.3rem 0.4rem;
	border-radius: 0.5rem;
	cursor: pointer;
}
#review-star-homepage-rec {
	font-size: 1rem;
}
.star-rec:hover {
	background-color: rgb(107, 185, 240);
}
.star-rec:hover ~ .star-rec {
	background-color: rgb(107, 185, 240);
}

#more-res-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	transition: 0.3s ease;
	font-weight: bold;
}

#more-res-btn:hover {
	text-decoration: .2rem solid underline;
	color: rgb(107, 185, 240);
	cursor: pointer;
}

#footer {
    height: 8rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

#footer a{
    color: black;
}

.footer-button span {
    font-family: 'Bebas Neue', sans-serif;
    width: 50%;
	text-decoration: none;

}

.github-icon {
    font-size: 25px;
    line-height: 60px;
}

#josh-footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: 3rem;
	margin-right: 3rem;
}
#gino-footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: 3rem;
	margin-right: 3rem;
}
#stephen-footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: 3rem;
	margin-right: 3rem;
}

.footer-button {
	margin: .5rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.linkedin-icon {
	font-size: 25px;
    line-height: 60px;
	color: #0A66C2;
}

#footer-wrapper {
	display: flex;
	margin-left: 1.5rem;
	margin-right: 1.5rem;
}

#footer-title {
	font-family: 'Poppins', sans-serif;
	font-size: 1.5rem;
	height: 2rem;

}

#link-wrapper {
	display: flex;
	height: 2.5rem;
}
.linkedin-icon:hover {
	font-size: 30px;
	transition: .3s ease-in;
}
.github-icon:hover {
	font-size: 30px;
	transition: .3s ease-in;
}


/* restaurant end */
